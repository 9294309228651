import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const localStorageKey = 'consent_granted';
const localStorageValue = 'true';

const ConsentBanner = () => {
  // we default to true so that the banner does not flicker
  // when the user has already given consent
  const [consentGranted, setConsentGranted] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem(localStorageKey);
    setConsentGranted(consent === localStorageValue);
  }, []);

  if (consentGranted) return null;

  const handleConsentPress = () => {
    localStorage.setItem(localStorageKey, localStorageValue);
    setConsentGranted(true);

    gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  return (
    <Container>
      <Text>Diese Webseite verwendet Cookies.</Text>
      <Button onClick={handleConsentPress}>Einverstanden</Button>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 20px 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  background-color: #6296cf;
  color: white;
  font-size: 14px;

  @media (min-width: 700px) {
    align-self: center;
    left: auto;
    right: auto;
    justify-content: center;
  }
`;

const Button = styled.button`
  display: inline-block;
  font-size: 18px;
  color: white;
  background-color: #6296cf;
  border: none;
  padding: 0;
  font-weight: 400;
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
`;

const Text = styled.span`
  font-size: 16px;
  color: white;
`;

export default ConsentBanner;
